export function videoPause() {
    function initializeVideoIntersectionObserver(margin = 0) {
        const videos = document.querySelectorAll('video');
    
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                const video = entry.target;
    
                if (entry.isIntersecting && video.getAttribute('src')) {
                    // Play the video when it's partially within the viewport
                    video.play();
                    console.log('video played');
                } else {
                    // Pause the video when it's not within the viewport
                    video.pause();
                    console.log('video paused');

                }
            });
        }, {
            root: null,
            rootMargin: `${margin}px`
        });
    
        // Observe each video element
        videos.forEach(video => {
            observer.observe(video);
        });
    }
    
    // Initialize the observer with a margin 
    initializeVideoIntersectionObserver(400);
    
}

