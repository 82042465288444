export function ariaLabels () {
    /* aria hidden attributes are set in the textsplit functions */
    function setAriaLabelForTextElements() {
        let containers = document.querySelectorAll('[data-split-container]');
        
        containers.forEach(container => {
            let textElementsInContainer = container.querySelectorAll('p, h3, h4');
            
            textElementsInContainer.forEach(element => {
                if (!element.classList.contains('no-label')) {
                    let originalText = element.innerText;
                    element.setAttribute('aria-label', originalText); 
                }
            });
        });

        let individualTextElements = document.querySelectorAll('[data-split-element]');
        
        individualTextElements.forEach(element => {
            let originalText = element.innerText; 
            element.setAttribute('aria-label', originalText);
        });
    }

    setAriaLabelForTextElements();

}