export function form() {
    document.addEventListener('DOMContentLoaded', function () {
        // Find the element with class 'formblock'
        var formBlock = document.querySelector('.formblock');
      
        if (formBlock) {
          // Add a MutationObserver to watch for attribute changes
          var observer = new MutationObserver(function (mutationsList) {
            for (var mutation of mutationsList) {
              if (mutation.type === 'attributes' && mutation.attributeName === 'data-process') {
                // Check if the data-process attribute is set to "success"
                if (formBlock.getAttribute('data-process') === 'success') {
                  // Log "form success" to the console
                  console.log('form success');
                  
                  // Remove the element with class 'popup-instruction'
                  var popupInstruction = document.querySelector('.popup-instruction');
                  if (popupInstruction) {
                    popupInstruction.remove();
                  }
                }
              }
            }
          });
      
          // Configure and start observing the target element
          observer.observe(formBlock, { attributes: true });
        }
      });
}