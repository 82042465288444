export function textServices() {
    gsap.registerPlugin(ScrollTrigger, SplitText);
  
    let split9 = new SplitText("#text-9", {type: "words"});
    let split10 = new SplitText("#text-10", {type: "words"});

    let words9 = split9.words;
    words9.forEach((word) => {
      word.setAttribute('aria-hidden', true);
    })
    let words10 = split10.words;
    words10.forEach((word) => {
      word.setAttribute('aria-hidden', true);
    })

    let mm = gsap.matchMedia();

    mm.add("(min-width: 920px)", () => {
    // desktop setup code here...

    const tlServices = gsap.timeline({  
      scrollTrigger: {
      trigger: '.services', 
      start: 'top+=150 bottom', 
      scrub: false, 
      pin: false,
      pinSpacing: false,
      // markers: true,
      // end: 'bottom bottom',
      },
    });

    tlServices.from(split9.words, {duration: 0.2, y: -20, rotation: -15, autoAlpha: 0, ease: "power4.inOut", stagger: 0.03});
    tlServices.from(split10.words, {duration: 0.2, y: -20, rotation: -15, autoAlpha: 0, ease: "power4.inOut", stagger: 0.03});

    });

    mm.add("(max-width: 919px)", () => {
    // mobile setup code here...

    const tlServices = gsap.timeline({  
      scrollTrigger: {
      trigger: '.services', 
      start: 'top+=150 bottom', 
      scrub: false, 
      pin: false,
      pinSpacing: false,
      // markers: true,
      // end: 'bottom bottom',
      },
    });

    tlServices.from(split9.words, {duration: 0.2, y: -20, rotation: -15, autoAlpha: 0, ease: "power4.inOut", stagger: 0.04});

    const tlServices2 = gsap.timeline({  
      scrollTrigger: {
      trigger: '.services .right', 
      start: 'top center+=50', 
      scrub: false, 
      pin: false,
      pinSpacing: false,
      // markers: true,
      // end: 'bottom bottom',
      },
    });

    tlServices2.from(split10.words, {duration: 0.3, y: -20, rotation: -15, autoAlpha: 0, ease: "power4.inOut", stagger: 0.02});
    

    });

    



}