export function scrollBar() {
    if (window.innerWidth > 920) {
        window.onscroll = function() {scrollUpdate()};
      
      function scrollUpdate() {
        var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        var scrolled = (winScroll / height) * 100;
        document.getElementById("scroll-bar").style.height = scrolled + "%";
        document.getElementById("arrow").style.top = "calc(" + scrolled + "% - 4px)";
      }
      
      } else {
        console.log("No dynamic scroll bar")
      }
}