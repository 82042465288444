export function textContact() {
    gsap.registerPlugin(ScrollTrigger, SplitText);

    let split11 = new SplitText("#text-11", {type: "words"});
    let split12 = new SplitText("#text-12", {type: "words"});

    let words11 = split11.words;
    words11.forEach((word) => {
      word.setAttribute('aria-hidden', true);
    })

    let words12 = split12.words;
    words12.forEach((word) => {
      word.setAttribute('aria-hidden', true);
    })
    
    const button = document.getElementById("togglePopup");

    const tl = gsap.timeline({  
        scrollTrigger: {
        trigger: '.contact', 
        start: 'top+=50 bottom', 
        scrub: false, 
        pin: false,
        pinSpacing: false,
        // markers: true,
        // end: 'bottom bottom',
    },
    });

    tl.from(split11.words, {duration: 0.2, y: -20, rotation: 0, autoAlpha: 0, ease: "power3.in", stagger: 0.04});

    const tl2 = gsap.timeline({  
        scrollTrigger: {
        trigger: '.contact', 
        start: 'center+=200 bottom', 
        scrub: false, 
        pin: false,
        pinSpacing: false,
        // markers: true,
        // end: 'bottom bottom',
    },
    });

    tl2.from(split12.words, {duration: 0.2, y: -20, rotation: 0, autoAlpha: 0, stagger: 0.03});
    tl2.fromTo(".popup-button", {opacity: 0, scale: 1.2}, {opacity: 1, scale: 1, duration: 0.05, ease: "power4.in"});


}