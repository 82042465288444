export function popup() {
    const toggleButton = document.getElementById("togglePopup");
    const popup = document.getElementById("popup");
    const closePopupButton = document.getElementById("closePopup");
    const html = document.documentElement;
    const popupWindow = document.getElementById("popup-window");
    let previouslyCreatedSmoother = ScrollSmoother.get();

    toggleButton.addEventListener("click", () => {
        popup.classList.add("before-active"); 
        html.style.overflow = "hidden";
        if (previouslyCreatedSmoother) {
          previouslyCreatedSmoother.paused(true);
          console.log('Smoother paused');
        }
        setTimeout(() => {
            popup.classList.add("active");
            // Add event listener for clicks outside the popup when it's active
            document.addEventListener('click', handleOutsideClick);
        }, 100); 
    });
    
    closePopupButton.addEventListener("click", () => {
        closePopup();
    });

    // Function to close the popup
    function closePopup() {
        if (previouslyCreatedSmoother) {
          previouslyCreatedSmoother.paused(false);
          console.log('Smoother continued');
        }
        popup.classList.remove("active");
        popup.classList.remove("before-active"); 
        html.style.removeProperty('overflow');
        document.removeEventListener('click', handleOutsideClick);
    }

    // Listen for clicks outside the popup and remove it
    function handleOutsideClick(e) {
        if (!popupWindow.contains(e.target)) {
            closePopup();
        }
    }
}

