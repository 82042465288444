export function popupVideo() {
    document.addEventListener('DOMContentLoaded', function () {
        // Function to handle button click
        function handleButtonClick(event) {
          // Check if the clicked element has the class "video-popup-button"
          if (event.target.classList.contains('video-popup-button')) {
            // Fetch data attributes
            const videoUrl = event.target.getAttribute('data-video');
            const posterUrl = event.target.getAttribute('data-poster');
            const aspectRatio = event.target.getAttribute('data-aspect');
            const customClass = event.target.getAttribute('data-class');
            const html = document.documentElement;
            let previouslyCreatedSmoother = ScrollSmoother.get();

            //Disable scroll
            html.style.overflow = "hidden";
            if (previouslyCreatedSmoother) {
              previouslyCreatedSmoother.paused(true);
              console.log('Smoother paused');
            }

            // Create and append the video popup wrapper to the body
            const videoPopupWrapper = document.createElement('div');
            videoPopupWrapper.className = 'video-popup-wrapper';
            document.body.appendChild(videoPopupWrapper);

            // Create and append the video popup to the wrapper
            const videoPopup = document.createElement('div');
            videoPopup.className = `video-popup iframe-container`;
            videoPopup.innerHTML = `
              <iframe class="iframe ${customClass}" src="${videoUrl}" style="width: 100%; background: url('${posterUrl}'); background-size: contain !important; background-repeat: no-repeat !important; aspect-ratio: ${aspectRatio}; " frameborder="0" allow="autoplay" allowfullscreen></iframe>
              <span class="close-button" id="closePopup">
                <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.52942 5.65625L17.8431 16.97" stroke="#171424" stroke-width="1.5" stroke-linecap="round"/>
                  <path d="M6.52942 16.9707L17.8431 5.65699" stroke="#171424" stroke-width="1.5" stroke-linecap="round"/>
                </svg>
              </span>
            `;
            videoPopupWrapper.appendChild(videoPopup);
    
            // Fetch all iframes with class 'iframe'
            const iframes = document.querySelectorAll('.iframe');
    
            // Apply transition styles after a short delay
            setTimeout(() => {
              videoPopupWrapper.classList.add('active');
            }, 100);
        
            // Listen for clicks outside the video popup (including close button) and remove it
            function handleOutsideClick(e) {
              let clickedInsideIframe = false;
              iframes.forEach(iframe => {
                if (iframe.contains(e.target)) {
                  clickedInsideIframe = true;
                }
              });
    
              if (!clickedInsideIframe) {
                videoPopupWrapper.remove();
                html.style.removeProperty('overflow');
                if (previouslyCreatedSmoother) {
                  previouslyCreatedSmoother.paused(false);
                  console.log('Smoother continued');
                }
                document.removeEventListener('click', handleOutsideClick);
              }
            }
    
            document.addEventListener('click', handleOutsideClick);
          }
        }
    
        // Listen for click events on the document
        document.addEventListener('click', handleButtonClick);
      });
}