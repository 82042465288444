export function lazyLoading() {
  
  // Function to get rootMargin for Video-1
  function getRootMarginVideo1() {
    if (window.innerWidth < 920) {
      return '700px 0px'; // Adjust for smaller screens
    } else {
      return '1500px 0px'; // Default for larger screens
    }
  }

  // Function to get rootMargin for Video-2
  function getRootMarginVideo2() {
    if (window.innerWidth < 920) {
      return '700px 0px'; // Adjust for smaller screens
    } else {
      return '3500px 0px'; // Default for larger screens
    }
  }

  // Function to get rootMargin for Images
  function getRootMarginImages() {
    if (window.innerWidth < 920) {
      return '400px 0px'; // Adjust for smaller screens
    } else {
      return '3000px 0px'; // Default for larger screens
    }
  }

  // Function to initialize observers
  function initializeObservers() {
    // Video-1
    const observerVideo1 = lozad('.lozad-video-1', {
      threshold: 0,
      rootMargin: getRootMarginVideo1(),
      loaded: function (el) {
        console.log('Video-1 is now loaded', el);
      }
    });

    // Video-2
    const observerVideo2 = lozad('.lozad-video-2', {
      threshold: 0,
      rootMargin: getRootMarginVideo2(),
      loaded: function (el) {
        console.log('Video-2 is now loaded', el);
      }
    });

    // Images
    const observerImages = lozad('.lozad-image', {
      threshold: 0,
      rootMargin: getRootMarginImages(),
      loaded: function (el) {
        console.log('Images are now loaded', el);
      }
    });

    // Start observing
    observerVideo1.observe();
    observerVideo2.observe();
    observerImages.observe();

    return { observerVideo1, observerVideo2, observerImages };
  }

  // Initialize observers
  let { observerVideo1, observerVideo2, observerImages } = initializeObservers();

  // Event listener to adjust rootMargin on window resize
  window.addEventListener('resize', function() {
    // Reinitialize observers with new root margins
    ({ observerVideo1, observerVideo2, observerImages } = initializeObservers());
  });
}

