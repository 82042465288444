export function workSlider() {
    window.addEventListener('DOMContentLoaded', function () {
        var screenWidth = window.innerWidth;

        if (screenWidth < 920) {
          console.log('No slider');

        } else {
            
                var swiper = new Swiper(".mySwiper", {
                    slidesPerView: "auto",
                    spaceBetween: 5,
                    speed: 800,
                    direction: "horizontal",
                    loop: true,
                    grabCursor: true,
                    freeMode: true,
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                    },
                    // autoplay: false,
                    autoplay: {
                        delay: 3000,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true,
                    }
                });
                console.log('Slider active');
        }
    });
}
