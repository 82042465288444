export function emotion() {

gsap.registerPlugin(ScrollTrigger, SplitText);
gsap.set('#emotion', {
  clipPath: 'circle(0.01% at 50% 50%)'
})

let split5 = new SplitText("#text-5", {type: "words"});
let split6 = new SplitText("#text-6", {type: "words"});
// let split7 = new SplitText("#text-7", {type: "words"});

let words5 = split5.words;
words5.forEach((word) => {
  word.setAttribute('aria-hidden', true);
})

let words6 = split6.words;
words6.forEach((word) => {
  word.setAttribute('aria-hidden', true);
})

let mm = gsap.matchMedia();

  mm.add("(min-width: 1440px)", () => {
    // desktop setup code here...
    console.log('emotion desktop version active');

    const tlEmotion = gsap.timeline({  
      scrollTrigger: {
      trigger: '.gsap-container', 
      start: 'top top', 
      scrub: true, 
      pin: true,
      pinSpacing: true,
      markers: false,
      end: '+=2000',
      },
    });

    // ScrollTrigger.create({  
    //   trigger: ".gsap-container",
    //   anticipatePin: 1,
    //   start: "top top",
    //   pin: true,
    //   end: '+=2100',
    // });

    // const tlEmotion = gsap.timeline({
    //   scrollTrigger: {
    //     trigger: '.introduction-text',
    //     start: 'top top+=400',
    //     scrub: true,
    //     // pin: true,
    //     // pinSpacing: true,
    //     markers: false,
    //     end: '+=2500',
    //   },    
    // });

    tlEmotion.add(() => {}, "+=0.5"); 
    tlEmotion.to(split5.words, {duration: 0.5, y: -20, rotation: 0, autoAlpha: 0, ease: "power3.in", stagger: 0.03});
    tlEmotion.to(split6.words, {duration: 0.2, y: -20, rotation: 0, autoAlpha: 0, ease: "power4.out", stagger: 0.02});
    // tlEmotion.add(() => {}, "+=1.3"); 
    tlEmotion.to('#emotion', {
      clipPath: 'circle(99.9% at 50% 50%)',
      duration: 1.5, 
    })
    // tlEmotion.to(split7.words, {duration: 0.3, y: -20, rotation: 0, autoAlpha: 0, ease: "power3.inOut", stagger: 0.05});
    tlEmotion.add(() => {}, "+=0.3");
    // tlEmotion.to(split7.words, {duration: 0.3, y: -20, rotation: 0, autoAlpha: 0, ease: "power4.out", stagger: 0.02});
  });

  mm.add("(max-width: 1439px)", () => {
    // mobile setup code here...

    console.log('emotion mobile version active');

    const tlEmotion = gsap.timeline({  
      scrollTrigger: {
      trigger: '.gsap-container', 
      start: 'top top', 
      scrub: true, 
      pin: true,
      pinSpacing: true,
      markers: false,
      end: '+=1300',
    },
    });

    // ScrollTrigger.create({  
    //   trigger: ".gsap-container",
    //   anticipatePin: 1,
    //   start: "top top",
    //   pin: true,
    //   end: '+=2100',
    // });

    // const tlEmotion = gsap.timeline({
    //   scrollTrigger: {
    //     trigger: '.introduction-text',
    //     start: 'top top+=300',
    //     scrub: true,
    //     // pin: true,
    //     // pinSpacing: true,
    //     markers: false,
    //     end: '+=1800',
    //   },    
    // });

    tlEmotion.add(() => {}, "+=0.5"); 
    tlEmotion.to(split5.words, {duration: 0.2, y: -20, rotation: 0, autoAlpha: 0, ease: "power3.in", stagger: 0.05});
    tlEmotion.to(split6.words, {duration: 0.1, y: -20, rotation: 0, autoAlpha: 0, ease: "power4.out", stagger: 0.03});
    // tlEmotion.add(() => {}, "+=1.5"); 
    tlEmotion.to('#emotion', {
      clipPath: 'circle(99.9% at 50% 50%)',
      duration: 2, 
    })
    tlEmotion.add(() => {}, "+=0.5"); 
    // tlEmotion.to(split7.words, {duration: 0.3, y: -20, rotation: 0, autoAlpha: 0, ease: "power3.inOut", stagger: 0.03});    
  });

}





