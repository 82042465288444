export function manageReload() {

    const breakpoint = 920;

    function reloadPageOnResize() {
    let windowWidth = window.innerWidth;

    window.addEventListener('resize', function() {
        const newWindowWidth = window.innerWidth;

        if ((windowWidth < breakpoint && newWindowWidth >= breakpoint) || 
            (windowWidth >= breakpoint && newWindowWidth < breakpoint)) {
        window.location.reload();
        }

        windowWidth = newWindowWidth;
    });
    }

    reloadPageOnResize();
}