export function textSensory() {
    gsap.registerPlugin(ScrollTrigger, SplitText);

    let split = new SplitText("#text-8", {type: "words"});
    let words = split.words;
    words.forEach((word) => {
      word.setAttribute('aria-hidden', true);
    })


    const tl = gsap.timeline({  
        scrollTrigger: {
        trigger: '.sensory', 
        start: 'top center', 
        scrub: false, 
        pin: false,
        // pinSpacing: true,
        // end: '+=1000',
        // markers: true,
      },
    });

    tl.from(split.words, {duration: 0.3, y: -40, rotation: 0, autoAlpha: 0, ease: "power3.out", stagger: 0.02});
    tl.add(() => {}, "+=1");

}