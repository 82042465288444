import Intro from './components/intro';
import { introStory } from './components/intro-story'; 
import { safari } from './components/safari';
import { lazyLoading } from './components/lazy-loading'; 
import { emotion } from './components/emotion'; 
import { lottieIntro } from './components/lottie-intro';
import { popup } from './components/popup';
import { textSensory } from './components/text-sensory';
import { textServices } from './components/text-services';
import { textContact } from './components/text-contact';
import { menu } from './components/menu';
import { popupVideo } from './components/popup-video';
import { scrollBar } from './components/scrollbar';
import { form } from './components/form';
import { videoPause } from './components/video-pause';
import { sliderMobile } from './components/slider-mobile';
import { workSlider } from './components/work-slider';
import { manageReload } from './components/loading';
import { ariaLabels } from './components/aria-labels';


const introElement = document.querySelector( '[data-intro]' );
if ( introElement ) {
    const intro = new Intro( introElement );
}

gsap.registerPlugin(ScrollSmoother);

let mm = gsap.matchMedia();

//check if device is touch device
if (!('ontouchstart' in window || navigator.maxTouchPoints)) {
  mm.add("(min-width: 1250px)", () => {
      // desktop setup code here...
      let smoother = ScrollSmoother.create({
        wrapper: "#smooth-wrapper",
        content: "#smooth-content",
        smooth: 2.5,
        normalizeScroll: true,
        effects: true,
        ignoreMobileResize: true
      });
      console.log('Matchmedia Smoother active');
    });
} else {
  console.log('No Smoother active');
}


// mm.add("(min-width: 1370px)", () => {
//   // desktop setup code here...
//   let smoother = ScrollSmoother.create({
//     wrapper: "#smooth-wrapper",
//     content: "#smooth-content",
//     smooth: 2.5,
//     normalizeScroll: true,
//     effects: true,
//     ignoreMobileResize: true
//   });
//   console.log('Matchmedia Smoother active');
// });


ariaLabels();
introStory();
safari();
lazyLoading();
emotion();
lottieIntro();
popup();
textSensory();
textServices();
textContact();
menu();
popupVideo();
scrollBar();
form();
videoPause();
sliderMobile();
workSlider();
manageReload();

