// import { gsap } from 'gsap';
// import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { BokehPass } from 'three/examples/jsm/postprocessing/BokehPass.js';

export default class Intro {

    constructor( element ) {

        // Needed for Webpack 5 :(
        // https://gsap.com/community/forums/topic/24286-scrolltrigger-and-webpack-prod-build/
        gsap.core.globals( 'ScrollTrigger', ScrollTrigger );

        this.element = element;
        this.canvas = this.element.querySelector( '[data-intro-canvas' );

        this.timeline = { value: 0 };
        this.mouse = new THREE.Vector2();
        this.mouseTarget = new THREE.Vector2();
        this.playing = false;
        this.ringGroups = [];
        this.ringMeshes = [];
        this.time = 0;

        this.rotationMultiplier = { value: 0 };
        this.shouldRotate = false;

        this.gltf = null;
        this.env = null;
        this.animation = null;
        this.mixer = null;

        this.mouse = { x: 0, y: 0 };
        // this.mouseMoveHandler = ( event ) => { this.onMouseMove( event ); };

        this.initScene();
        this.addEventListeners();
    }

    initScene() {

        this.scene = new THREE.Scene();
        this.loader = new GLTFLoader();
        this.dracoLoader = new DRACOLoader();

        this.camera = new THREE.PerspectiveCamera( 45, this.canvas.clientWidth / this.canvas.clientHeight, 0.1, 1000 );
        this.camera.position.set( 0, 0, 10 );
        this.camera.lookAt( this.scene.position );

        // this.light = new THREE.DirectionalLight( 0xffffff, 0.5 );
        // this.light.position.set( 100, 150, -120 );

        this.hemisphereLight = new THREE.HemisphereLight( 0xC9F2FF, 0x8CF1B3, 1 ); // Parameters: sky color, ground color, intensity
        this.scene.add( this.hemisphereLight );

        this.ambientLight = new THREE.AmbientLight( 0xDDFFEA, 0.3 ); // Parameters: color, intensity
        this.scene.add( this.ambientLight );



        this.renderer = new THREE.WebGLRenderer( { alpha: false } );
        this.renderer.setSize( this.canvas.clientWidth, this.canvas.clientHeight );
        this.renderer.setPixelRatio( window.devicePixelRatio );
        this.renderer.shadowMap.enabled = true;
        this.renderer.setClearColor( 0xF8FFFB, 1 );
        this.canvas.appendChild( this.renderer.domElement );

        // this.initDebugGeometry();
        this.loadEnvironmentMap();
        this.initEffectComposer();
    }

    initEffectComposer() {
        this.composer = new EffectComposer( this.renderer );

        this.renderPass = new RenderPass( this.scene, this.camera );
        this.composer.addPass( this.renderPass );

        this.bokehPass = new BokehPass( this.scene, this.camera, {
            focus: this.camera.position.z,
            aperture: 0.0015,
            maxblur: 0.0,
        } );
        this.composer.addPass( this.bokehPass );
    }

    initDebugGeometry() {
        let geometry = new THREE.PlaneGeometry( 5, 5, 20, 20 );
        let material = new THREE.MeshBasicMaterial( { color: 0x000000, side: THREE.DoubleSide, wireframe: true, wireframeLinewidth: 4 } );
        this.plane = new THREE.Mesh( geometry, material );
        this.scene.add( this.plane );
    }

    loadEnvironmentMap() {

        var textureLoader = new THREE.TextureLoader().load( window.assets_uri + '/gltf/HDR_Light_Studio_Free_HDRI_Design_25_LowContrast.jpg', ( texture ) => {
            this.env = texture;
            texture.mapping = THREE.EquirectangularReflectionMapping;
            this.scene.environment = texture;
        } );

        // new RGBELoader().load( window.assets_uri + '/gltf/HDR_Light_Studio_Free_HDRI_Design_25.hdr', ( texture ) => {
        //     texture.mapping = THREE.EquirectangularReflectionMapping;
        //     this.scene.environment = texture;
        //     this.initSphere();
        // } );
    }

    initSphere() {
        const rings = 23;
        const duration = 5;
        const delay = 200;
        // const thickness = .085;
        const thickness = .045;


        for ( var i = 0; i < rings; i++ ) {

            const geometry = new THREE.TorusGeometry( 3, thickness, 16, 60 );

            // const glassMaterial = new THREE.MeshStandardMaterial( {
            //     color: 0xFAFAFA,
            //     side: THREE.DoubleSide,
            //     transparent: false,
            //     opacity: 1,
            //     roughness: 0.9, // Adjust roughness to control the surface roughness
            //     metalness: 0.0, // Set metalness to 0 for non-metallic appearance
            // } );

            const glassMaterial = new THREE.MeshPhysicalMaterial( {
                color: 0xFFFFFF,
                metalness: 0,
                roughness: .1,
                envMapIntensity: 0.7,
                clearcoat: 0,
                transparent: false,
                opacity: 1,
                reflectivity: 1,
                ior: 1.5,
                side: THREE.DoubleSide,
            } );

            const group = new THREE.Group();
            const torus = new THREE.Mesh( geometry, glassMaterial );

            torus.scale.set( 0, 0, 0 );
            torus.rotation.x = ( Math.PI / 180 ) * 90;
            torus.thickness = { value: thickness };

            this.ringMeshes.push( torus );
            this.ringGroups.push( group );

            group.add( torus );
            this.scene.add( group );

            this.setRingTween( torus, i * delay, duration, thickness );
        }
    }

    setRingTween( torus, delay, duration, thickness ) {

        // yikes?
        setTimeout( () => {
            const tween = gsap.timeline( {
                repeat: -1, onRepeat: () => {
                    torus.geometry.dispose();
                    torus.geometry = new THREE.TorusGeometry( 3, thickness, 16, 60 );
                }
            } );
            tween.set( torus.scale, { x: 0, y: 0, z: 0 } );
            tween.to( torus.scale, { x: 1, y: 1, z: 1, duration: duration, ease: 'power1.out' } );
            tween.to( torus.thickness, {
                value: 0, duration: duration / 2, ease: 'power1.out', delay: duration / 2, onUpdate: ( e ) => {
                    torus.geometry.dispose();
                    torus.geometry = new THREE.TorusGeometry( 3, torus.thickness.value, 16, 60 );
                }
            }, 0 );
        }, delay );
    }

    addEventListeners() {
        let observerInitialized = false;
        window.onIntroComplete = () => {
            this.initSphere();

            // Setup IntersectionObserver after initSphere
            if (!observerInitialized) {
                const observer = new IntersectionObserver(entries => {
                    entries.forEach(entry => {
                        if (entry.isIntersecting) {
                            this.playing = true;
                            this.render();
                            document.addEventListener('mousemove', this.mouseMoveHandler);
                            console.log('3d intro playing');
                        } else {
                            this.playing = false;
                            document.removeEventListener('mousemove', this.mouseMoveHandler);
                            console.log('3d intro paused');
                        }
                    });
                }, {
                    root: null,
                    rootMargin: '-20px 0px 20px 0px',
                    threshold: 0
                });

                // Assuming this is your 3D object
                const element = document.querySelector('.right');

                // Start observing the element
                observer.observe(element);
                observerInitialized = true;
            }
        };

        let mm = gsap.matchMedia();

        mm.add("(min-width: 1440px)", () => {
        // desktop setup code here...
            console.log('desktop 3d intro active');
            gsap.to( this.timeline, {
                value: 1,
                ease: 'none',
                scrollTrigger: {
                    // trigger: '.scroll-animation',
                    // scrub: true,
                    // start: '-1px top',
                    // endTrigger: '.trigger',
                    // end: 'bottom top',
                    // // markers: true,

                    trigger: '.right', 
                    start: 'top top', 
                    scrub: true, 
                    pin: true,
                    pinSpacing: true,
                    markers: false,
                    end: '+=3000',
                    endTrigger: ".trigger",

                    // onToggle: self => {
                    //     if ( self.isActive ) {
                    //         this.playing = true;
                    //         this.render();
                    //         document.addEventListener( 'mousemove', this.mouseMoveHandler );
                    //     } else {
                    //         this.playing = false;
                    //         document.removeEventListener( 'mousemove', this.mouseMoveHandler );
                    //     }
                    // },

                    onUpdate: self => {
                        if ( this.timeline.value > 0.35 ) {
                            if ( !this.shouldRotate ) {
                                this.shouldRotate = true;
                                gsap.to( this.rotationMultiplier, { value: 1, duration: 10, ease: 'power3.inOut' } );
                            }
                        }
                    }
                },
            } );
        });

        mm.add("(max-width: 1439px)", () => {
        // mobile setup code here...
            console.log('mobile 3d intro active');
            gsap.to( this.timeline, {
                value: 1,
                ease: 'none',
                scrollTrigger: {
                    // trigger: '.scroll-animation',
                    // scrub: true,
                    // start: '-1px top',
                    // endTrigger: '.trigger',
                    // end: 'bottom top',
                    // // markers: true,

                    trigger: '.right', 
                    start: 'top top', 
                    scrub: true, 
                    pin: true,
                    pinSpacing: true,
                    markers: false,
                    end: '+=2000',

                    // onToggle: self => {
                    //     if ( self.isActive ) {
                    //         this.playing = true;
                    //         this.render();
                    //         document.addEventListener( 'mousemove', this.mouseMoveHandler );
                    //     } else {
                    //         this.playing = false;
                    //         document.removeEventListener( 'mousemove', this.mouseMoveHandler );
                    //     }
                    // },

                    onUpdate: self => {
                        if ( this.timeline.value > 0.31 ) {
                            if ( !this.shouldRotate ) {
                                this.shouldRotate = true;
                                gsap.to( this.rotationMultiplier, { value: 1, duration: 10, ease: 'power3.inOut' } );
                            }
                        }
                    }
                },
            } );

        });

        addEventListener( 'resize', () => { this.resize(); } );

    }

    onMouseMove( event ) {
        this.mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
        this.mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;

    }

    render() {

        this.time += 0.005;

        this.mouseTarget.x = gsap.utils.interpolate( this.mouseTarget.x, this.mouse.x, 0.03 );
        this.mouseTarget.y = gsap.utils.interpolate( this.mouseTarget.y, this.mouse.y, 0.03 );

        this.scene.rotation.set(
            gsap.utils.mapRange( 0, 1, ( Math.PI / 180 ) * 90, ( Math.PI / 180 ) * -360, this.timeline.value ),
            this.mouseTarget.x * 1.25,
            this.mouseTarget.x * 0.1
        );

        for ( let i = 0; i < this.ringMeshes.length; i++ ) {
            const mesh = this.ringMeshes[i];
            mesh.position.y = Math.sin( this.time + i * .2 ) * .5 * ( 1 - this.rotationMultiplier.value );
            mesh.rotation.y += .01 * this.rotationMultiplier.value;
            mesh.rotation.z += .025 * this.rotationMultiplier.value;
        }

        for ( let i = 0; i < this.ringGroups.length; i++ ) {
            const group = this.ringGroups[i];
            group.rotation.x = ( this.rotationMultiplier.value * 2.5 + ( i * 0.05 ) ) * this.rotationMultiplier.value;
            group.rotation.y = ( this.rotationMultiplier.value * 4.8 + ( i * 0.1 ) ) * this.rotationMultiplier.value;
            group.rotation.z = ( this.rotationMultiplier.value * 6.6 + ( i * 0.15 ) ) * this.rotationMultiplier.value;
        }

        this.composer.render();

        if ( this.playing ) {
            requestAnimationFrame( () => { this.render(); } );
        }
    }

    resize() {
        this.camera.aspect = this.canvas.clientWidth / this.canvas.clientHeight;
    }
}