export function sliderMobile() {
    
    gsap.registerPlugin(ScrollTrigger);

    let mm = gsap.matchMedia();
    mm.add("(max-width: 919px)", () => {
        // mobile setup code here...
        let swiperSlides = document.querySelectorAll('.swiper-slide');
        swiperSlides.forEach((slide, index) => {
            /////
            // let tl = gsap.timeline({
            //     scrollTrigger: {
            //     trigger: slide,
            //     start: "top bottom", 
            //     scrub: false,
            //     markers: false,
            //     },
            // });
            // let hoverElement = slide.querySelector('.hover');

            // tl.from(slide, {
            //     duration: 0.4,
            //     y: 200,
            //     ease: "power3.in",
            // });
            // tl.add(() => {}, "+=0.6");
            // tl.fromTo(hoverElement, {
            //     opacity: 0.1,
            //     filter: "blur(5px)",
            // }, {
            //     duration: 0.4,
            //     ease: "power3.out",
            //     opacity: 1,
            //     filter: "blur(0px)",
                
            // });
            /////
            let tl = gsap.timeline({
                scrollTrigger: {
                trigger: slide,
                start: "top bottom", 
                scrub: false,
                markers: false,
                },
            });

            tl.from(slide, {
                duration: 0.4,
                y: 200,
                ease: "power3.in",
            });

            let hoverElement = slide.querySelector('.hover');

            let tl2 = gsap.timeline({
                scrollTrigger: {
                trigger: hoverElement,
                start: "top-=170 bottom", 
                scrub: false,
                markers: false,
                },
            });

            tl2.fromTo(hoverElement, {
                opacity: 0.3,
                filter: "blur(5px)",
            }, {
                duration: 0.6,
                ease: "power2.in",
                opacity: 1,
                filter: "blur(0px)",
                
            });

        });
    
    });

}