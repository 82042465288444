export function safari() {

    // Check if the device is not a touch device
if (!('ontouchstart' in window)) {
    // Check if it's Safari browser
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    if (isSafari) {
        console.log("Safari on desktop detected");


        // Remove all videos with class 'video'
        var videos = document.querySelectorAll('video');
        videos.forEach(function(video) {
            video.parentNode.removeChild(video);
        });

    } else {

        console.log("Chrome or Firefox detected");
    }
} else {
    console.log("Touch device detected");
    // No smoother
}

}