export function lottieIntro() {


  let animation = document.getElementById("sw-intro");
  const body = document.querySelector('body');
  const html = document.documentElement;

  if (ScrollSmoother.get()) {
    let previouslyCreatedSmoother = ScrollSmoother.get();
    previouslyCreatedSmoother.paused(true);
    console.log("Smoother paused during intro");
  } else {
    console.log("No Smoother found to pause before intro");
  }

  animation.addEventListener("complete", () => {    
    console.log("Lottie intro complete");
    body.classList.add("intro-complete");
    html.style.removeProperty('overflow');
    var divToRemove = document.querySelector('.lottie-intro-wrapper');

    // Remove the lottie intro div
    if (divToRemove) {
        setTimeout(() => {
          divToRemove.parentNode.removeChild(divToRemove);
          console.log("Lottie intro removed");
        }, 1000);

    } else {
        console.log("Element with class 'lottie-intro-wrapper' not found.");
    }

    // Start playing the 3d rings animation
    if (window.onIntroComplete) {
      setTimeout(function() {
      if (window.onIntroComplete) {
          window.onIntroComplete();
      }
      }, 800); 
    }

    // Continue scrolling after intro
    if (ScrollSmoother.get()) {
      let previouslyCreatedSmoother = ScrollSmoother.get();
      setTimeout(() => {
        previouslyCreatedSmoother.paused(false);
        console.log('Smoother continued');
      }, 1100);
    } else {
      console.log("No Smoother found.");
    }

  });

}

