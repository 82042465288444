export function menu() {
    /* nog verwerken in script: focus trap in open menu en escape key close */

    document.addEventListener("DOMContentLoaded", function() {
        const hamburger = document.querySelector(".hamburger");
        const close = document.querySelector(".close");
        const navigation = document.querySelector(".navigation");
        const html = document.querySelector("html");

        // Helper function to set tabindex for menu items
        function updateMenuItemsTabIndex(isVisible) {
            const menuItems = navigation.querySelectorAll('.menu a');
            menuItems.forEach(item => {
                item.tabIndex = isVisible ? 0 : -1;
            });
        }

        // Open the navigation when hamburger is clicked
        hamburger.addEventListener("click", function(e) {
            e.stopPropagation();
            html.classList.add("menu-open");
            hamburger.setAttribute("aria-expanded", "true");
            hamburger.setAttribute("tabindex", "-1");
            navigation.setAttribute("aria-hidden", "false");
            close.setAttribute("tabindex", "0");
            updateMenuItemsTabIndex(true); // Make menu items focusable
        });

        // Close the navigation when close is clicked
        close.addEventListener("click", function() {
            html.classList.remove("menu-open");
            hamburger.setAttribute("aria-expanded", "false");
            hamburger.setAttribute("tabindex", "0");
            navigation.setAttribute("aria-hidden", "true");
            close.setAttribute("tabindex", "-1");
            updateMenuItemsTabIndex(false); // Make menu items non-focusable
        });

        // Close the navigation when clicking outside the navigation
        html.addEventListener("click", function(e) {
            if (html.classList.contains("menu-open") && !navigation.contains(e.target)) {
                html.classList.remove("menu-open");
                hamburger.setAttribute("aria-expanded", "false");
                navigation.setAttribute("aria-hidden", "true");
                close.setAttribute("tabindex", "-1");
                updateMenuItemsTabIndex(false); // Make menu items non-focusable
            }
        });

        gsap.registerPlugin(ScrollToPlugin);

        const menuItems = document.querySelectorAll('.menu .anchor-link');

        menuItems.forEach(item => {
            item.addEventListener('click', function(event) {
                event.preventDefault();
                html.classList.remove("menu-open");
                hamburger.setAttribute("aria-expanded", "false");
                navigation.setAttribute("aria-hidden", "true");
                close.setAttribute("tabindex", "-1");
                updateMenuItemsTabIndex(false); // Make menu items non-focusable

                const targetId = this.getAttribute('href');
                let offsetY = 100;
                gsap.to(window, { duration: 1.2, scrollTo: { y: targetId, offsetY: offsetY } });
            });
        });
    });
}
