export function introStory() {
    gsap.registerPlugin(ScrollTrigger, SplitText);

    // let split1 = new SplitText("#text-1", {type: "words"});
    let split2 = new SplitText("#text-2", {type: "words"});
    let split3 = new SplitText("#text-3", {type: "words"});
    let split4 = new SplitText("#text-4", {type: "words"});

    let words2 = split2.words;
    words2.forEach((word) => {
    word.setAttribute('aria-hidden', true);
    });
    let words3 = split3.words;
    words3.forEach((word) => {
    word.setAttribute('aria-hidden', true);
    });
    let words4 = split4.words;
    words4.forEach((word) => {
    word.setAttribute('aria-hidden', true);
    });




    let mm = gsap.matchMedia();

    mm.add("(min-width: 1440px)", () => {
    // desktop setup code here...

    let panels = gsap.utils.toArray("[data-item]", document);
    let tops = panels.map(panel => ScrollTrigger.create({trigger: panel, start: "top 0px"}));

    const tl2 = gsap.timeline({ 
        scrollTrigger: {
            trigger: '#text-2', 
            start: 'top bottom-=100', 
            scrub: false,
            // markers: {
            //     startColor: "purple",
            //     endColor: "fuchsia",
            //     fontSize: "3rem",
            //   },
            pinSpacing: false,
        },
    });

    const tl3 = gsap.timeline({ 
        scrollTrigger: {
            trigger: '#text-3', 
            start: 'top bottom-=100', 
            scrub: false, 
            // markers: {
            //     startColor: "yellow",
            //     endColor: "orange",
            //     fontSize: "3rem",
            //   },
            pinSpacing: false,
        },
    });

    const tl4 = gsap.timeline({ 
        scrollTrigger: {
            trigger: '#text-4', 
            start: 'top bottom-=100', 
            scrub: false, 
            // markers: {
            //     startColor: "green",
            //     endColor: "blue",
            //     fontSize: "3rem",
            //   },
            pinSpacing: false,
        },
    });

        tl2.from(split2.words, {duration: 0.4, y: 20, rotation: 0, autoAlpha: 0, ease: "power3.inOut", stagger: 0.05});
        tl3.from(split3.words, {duration: 0.4, y: 20, rotation: 0, autoAlpha: 0, ease: "power3.inOut", stagger: 0.05});
        tl4.from(split4.words, {duration: 0.4, y: 50, rotation: 0, autoAlpha: 0, ease: "power3.inOut", stagger: 0.06});
    });

    mm.add("(max-width: 1439px)", () => {
    // mobile setup code here...
        
    let panels = gsap.utils.toArray("[data-item]", document);
    let tops = panels.map(panel => ScrollTrigger.create({trigger: panel, start: "top 0px"}));

    const tl2 = gsap.timeline({ 
        scrollTrigger: {
            trigger: '#text-2', 
            start: 'top bottom-=100', 
            scrub: false,
            // markers: {
            //     startColor: "purple",
            //     endColor: "fuchsia",
            //     fontSize: "3rem",
            //   },
            pinSpacing: false,
        },
    });

    const tl3 = gsap.timeline({ 
        scrollTrigger: {
            trigger: '#text-3', 
            start: 'top bottom-=100', 
            scrub: false, 
            // markers: {
            //     startColor: "yellow",
            //     endColor: "orange",
            //     fontSize: "3rem",
            //   },
            pinSpacing: false,
        },
    });

    const tl4 = gsap.timeline({ 
        scrollTrigger: {
            trigger: '#text-4', 
            start: 'top bottom-=100', 
            scrub: false, 
            // markers: {
            //     startColor: "green",
            //     endColor: "blue",
            //     fontSize: "3rem",
            //   },
            pinSpacing: false,
        },
    });

        tl2.from(split2.words, {duration: 0.6, y: 20, rotation: 0, autoAlpha: 0, ease: "power3.inOut", stagger: 0.05});
        tl3.from(split3.words, {duration: 0.6, y: 20, rotation: 0, autoAlpha: 0, ease: "power3.inOut", stagger: 0.05});
        tl4.from(split4.words, {duration: 0.6, y: 50, rotation: 0, autoAlpha: 0, ease: "power3.inOut", stagger: 0.06});

        // tl2.fromTo(split2.words, {y: 40, autoAlpha: 0, stagger: 0.05}, {y: 0, autoAlpha: 0.5, duration: 0.8, ease: "power3.inOut"});


    });

    
}


